<template>
  <div class="container">
    <div
      class="column"
      v-for="n in columnCount"
      :key="n"
      ref="columns"
    >
      <div
        class="item"
        v-for="(item,index) in getItemsForColumn(n)"
        :key="index"
      >
        <!-- 瀑布流项内容 -->
        <slot :img="item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mainMenuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    //   mainMenuList: [
        // {
        //   id: 1,
        //   text: '我是1',
        //   url: 'https://preview.qiantucdn.com/freepik/512/679/679907.png%21qt_h320',
        // },
        // {
        //   id: 2,
        //   text: '我是2',
        //   url: 'https://pic.rmb.bdstatic.com/bjh/cms/240627/2b761b48734ac884b9733f3078c3f59f_1719469772.3799_15.png',
        // },
        // {
        //   id: 3,
        //   text: '我是3',
        //   url: 'https://p4.itc.cn/images01/20230106/5f201e56eda544a6af2814a6baadfb65.png',
        // },
        // {
        //   id: 4,
        //   text: '我是4',
        //   url: 'https://p4.itc.cn/images01/20230106/5f201e56eda544a6af2814a6baadfb65.png',
        // },
        // {
        //   id: 5,
        //   text: '我是5',
        //   url: 'https://st.900ppt.com/Upload/imgs/4b8d0a7860aa4f7e/1.jpg%21img1',
        // },
        // {
        //   id: 6,
        //   text: '我是6',
        //   url: 'https://t10.baidu.com/it/u=3470936574,226416430&fm=30&app=106&f=PNG?w=640&h=415&s=4810CF1816FC47886F4D2CD40300F0A0',
        // },
        // {
        //   id: 7,
        //   text: '我是7',
        //   url: 'https://www.yunyingwang.cc/wp-content/uploads/2023/07/4059276811967403773.png',
        // },
    //   ],
      columnCount: 6, // 根据屏幕宽度动态调整列数
    };
  },
  methods: {
    getItemsForColumn(columnIndex) {
      return this.mainMenuList.filter((_, index) => index % this.columnCount === columnIndex - 1);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.column {
  flex: 1;
  margin: 0 5px;
}

.item {
  margin-bottom: 15px;
  
}
</style>
