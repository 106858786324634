import request from '@/utils/request';
const VUE_APP_OPERATE = process.env.VUE_APP_OPERATE;
// 获取语种列表
export function queryGkLanguage() {
  return request({
    url: '/ads/queryGkLanguage',
    method: 'get',
  });
}

// 获取用户列表
export function userSearch(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/sys/user/search',
    method: 'get',
    params: {
      ...params,
    },
  });
}
