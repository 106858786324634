import request from '../utils/request';
import store from '@/store';
import qs from 'qs';
const BASE_URL = process.env.VUE_APP_BASE_GATEWAY;
const POST = (
  url,
  data,
  config = {
    baseURL: BASE_URL,
  },
) => {
  return request({
    method: 'post',
    url: url,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    ...config,
  });
};
const POST1 = (url, data) => {
  return request({
    method: 'post',
    url: url,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
};

const GET = (url, params, config = {}) => {
  return request({
    method: 'get',
    url: url,
    params,
    ...config,
  });
};
const GET1 = (
  url,
  params,
  config = {
    baseURL: '/fspider',
  },
) => {
  return request({
    method: 'get',
    url: url,
    params,
    ...config,
  });
};
// 获取推荐词列表
// TODO: 后端代码推到测试之后，前端往测试推的时候，把http://192.168.1.46:8088/api去掉
export const audiencesDetail = (data) => POST1(`/bigdata/audience/audiencesDetail`, data);
// 根据选中关键词查询FB受众
export const targetingSearch = (params) => GET('ads/targetingSearch', params);
// 根据广告组获取关联商品详情
export const findSaleByAdSetId = (params, id) =>
  GET('sale/findSaleByAdSetId', params, {
    headers: { thirdLoginUserId: id },
  });
// 根据广告系列获取关联商品
export const findSaleByCampaignIds = (params) => GET('sale/findSaleByCampaignIds', params);
// 受众词翻译
// 测试的地址：http://192.168.4.131:10003/api/slogan/onlineSloganTranslate
// 正式的地址：https://auto-ads-ask.giikin.com/api/slogan/onlineSloganTranslate
const TRANSLATE_URL =
  process.env.NODE_ENV === 'production' ? 'https://auto-ads-ask.giikin.com' : 'http://192.168.4.131:10003';
export const translate = (data) => POST1(`${TRANSLATE_URL}/api/slogan/onlineSloganTranslate`, data);

// 谷歌趋势RSS
export const googleTrends = (geoCode) => GET(`rss/googleTrends?geoCode=${geoCode}`);
// const GASS_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://gassapi.giikin.cn/fspider' : '/fspider';
// // 热搜词--地址：https://gassapi.giikin.cn/fspider/
// export const getHotWords = (params) =>
//   GET(`/google/dataStudioReporting`, params, {
//     baseURL: GASS_BASE_URL,
//   });

// 热搜词
const GASS_BASE_URL = 'https://gassapi.giikin.cn/fspider';
// 地址：https://gassapi.giikin.cn/fspider/google/dataStudioReporting
export const getHotWords = (params) => {
  console.log('cgl----GASS_BASE_URL--001', GASS_BASE_URL);
  console.log('cgl----GASS_BASE_URL--node_env', process.env);
  if (process.env.VUE_APP_API_ENV === 'development') {
    console.log('cgl----GASS_BASE_URL--003');
    return GET1(`/google/dataStudioReporting`, params);
  } else {
    console.log('cgl----GASS_BASE_URL--002', GASS_BASE_URL);
    return GET1(`${GASS_BASE_URL}/google/dataStudioReporting`, params);
  }
};

let fastBaseUrl =
  process.env.VUE_APP_API_ENV === 'development'
    ? 'http://192.168.4.196:8088/api'
    : process.env.VUE_APP_GATEWAY_FASTDELIVERY;
/**
 * 根据商品ID 获取 商品详情
 * @param {*} saleId
 * @returns
 */
export const getSaleDetails = (saleId) => GET(`${fastBaseUrl}/fastDelivery/sale/queryById?saleId=${saleId}`);
/**
 * 生成广告语
 * @param {*} saleId
 * @returns
 * https://gateway.giikin.com/fastDelivery/slogan/chatGPTSloganQuery
 */

export const createAd = (data) => {
  return POST1(`${fastBaseUrl}/slogan/chatGPTSloganQuery`, data, { timeout: 120000 });
};
